import { createCSSLinkElement, createScriptElement } from '@ems/common';
import { AppConfiguration } from './stores/appConfiguration';

const getAppFilename = (extension: string) => `emsAdminApp.${extension}`;

const boxIconsUrl = 'https://unpkg.com/boxicons@2.0.7/css/boxicons.min.css';

const jQueryUrl = 'https://code.jquery.com/jquery-3.4.1.slim.min.js';
const jqueryIntegrity = 'sha256-pasqAKBDmFT4eHoN2ndd6lN370kFiGUFyTiUHWhU7k8=';

export const loadAndStart = (appConfig: AppConfiguration) => {
    const {
        configuration: { assetUrl },
    } = appConfig;

    const appCSSPath = `${assetUrl}${getAppFilename('css')}`;
    const appJSPath = `${assetUrl}${getAppFilename('js')}`;

    window.document.head.append(
        createScriptElement({
            src: appJSPath,
            onload: () => {
                window.Mews.startEmsAdmin(appConfig);
            },
        }),
        /*  for slider  */
        createScriptElement({ src: jQueryUrl, integrity: jqueryIntegrity, crossOrigin: 'anonymous' }),
        createCSSLinkElement({ href: appCSSPath }),
        createCSSLinkElement({ href: boxIconsUrl })
    );
};
